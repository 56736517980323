$(function () {

    /* burger menu */
    $(window).on('load resize rotate', function() {
        var $headerServices__container = $('.header-services .size-container'),
            $headerServices__nav__ul = $('.header-services nav > ul'),
            $headerServices__inner = $('.header-services__inner');

        if ($(this).width() > 768) {
            $headerServices__inner.css('width', $headerServices__container.width() - $headerServices__nav__ul.width());
        }
    });

    /* -------- */

    /* buttons */
    $('.btn-icon').each(function () {
        var text = $(this).find('span').text();

        $(this).prepend('<span>' + text + '</span>');
    });

    $('.btn, .icon-link').on('click', function () {
        if ($(this).is('.burger-call')) {
            $('.header-services').toggleClass('active');

            $(this).toggleClass('active');

            $('body').toggleClass('locked');
        } else {
            $(this).addClass('active');
        }
    });
    //
    // $(document).on('click', function (e) {
    //     var el = '.btn';
    //
    //     if ($(e.target).closest(el).length) return false;
    //
    //     $(el).each(function () {
    //         if (!$(this).is('.burger-call')) $(this).removeClass('active');
    //     });
    // });

    /* -------- */

    /* box-placeholder */
    $('.box-placeholder input, .box-placeholder textarea, .box-placeholder select').on('focusout', function() {
        if ($(this).val() != '') {
            $(this).addClass('input-filled');
        } else {
            $(this).removeClass('input-filled');
        }
    })

    $('.box-placeholder .placeholder').on('click', function () {
        $(this).siblings('input, textarea').trigger('focus');
    });

    /* -------- */

    /* data href */

    // $(document).on('click', '[data-href]', function () {
    //     var href = $(this).data('href');
    //
    //     if (href) location.href = href;
    // });


    /* -------- */

    /* tables */
    $('table').each(function () {
        if (!$(this).is('.noresponsive')) {
            $(this).wrap('<div class="responsive-table"></div>');
        }
    });

    /* -------- */

    /* services */
    $('.services-all__title').on('click', function () {
        $(this).parent().toggleClass('active');
    });

    /* -------- */

    /* sliders */
    function autoSlider($wrapper, settings, arrowsDots) {
        if (!$wrapper || $wrapper.is('.noslider')) {
            return;
        }

        if ($wrapper.is('.slick-initialized')) {
            $wrapper.slick('unslick');
        }

        if (arrowsDots) {
            // при прохождении точек переломов и доп. стрелках
            $wrapper.on('breakpoint', function () {
                $(this).slick('unslick'); // разрушаем слайдер
                $(this).find('.wrap-dots').remove(); // удаляем нашу навигацию
                $(this).slick(settings); // запускаем заново
                myArrowsDots($(this)); // добавляем стрелки в навигацию
            });
        }

        $wrapper.slick(settings);

        if (arrowsDots) {
            myArrowsDots($wrapper);
        }

        return false;
    }

    // функция для дополнительных стрелок
    function myArrowsDots($wrapper) {
        var wrapClass = 'wrap-dots';

        $wrapper.find('.slick-dots').wrap('<div class="' + wrapClass + '"/>');

        $wrapper.find('.' + wrapClass)
            .prepend('<a class="dots-prev"/>')
            .append('<a class="dots-next"/>');

        $wrapper.find('.dots-prev').on('click', function() {
            $wrapper.slick('slickPrev');
        });

        $wrapper.find('.dots-next').on('click', function() {
            $wrapper.slick('slickNext');
        });

        return false;
    }

    var welcomeSliderSettings = {
        autoplay: false,
        speed: 600,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        customPaging: function (slider, i) {
            return $('<a data-role="none" role="button" tabindex="0"></a>');
        },
        rows: 0,
        fade: true,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    fade: false,
                    adaptiveHeight: true
                }
            }
        ]
    }
    autoSlider($('.welcome-slider'), welcomeSliderSettings, true);

    var servicesSliderSettings = {
        autoplay: false,
        speed: 600,
        arrows: true,
        nextArrow: '<a data-role="none" class="slick-next" aria-label="" tabindex="0" role="button"></a>',
        prevArrow: '<a data-role="none" class="slick-prev" aria-label="" tabindex="0" role="button"></a>',
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        rows: 0,
        fade: true,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    fade: false,
                    adaptiveHeight: true
                }
            }
        ]
    };
    autoSlider($('.services-top__slider'), servicesSliderSettings);

    var gallerySliderSettings = {
        autoplay: false,
        speed: 600,
        arrows: true,
        nextArrow: '<a data-role="none" class="slick-next" aria-label="" tabindex="0" role="button"></a>',
        prevArrow: '<a data-role="none" class="slick-prev" aria-label="" tabindex="0" role="button"></a>',
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        rows: 0,
        fade: true,
        cssEase: 'linear'
    };
    autoSlider($('.media-container__gallery'), gallerySliderSettings);

    var programsSliderSettings = {
        autoplay: false,
        speed: 600,
        arrows: true,
        nextArrow: '<a data-role="none" class="slick-next" aria-label="" tabindex="0" role="button"></a>',
        prevArrow: '<a data-role="none" class="slick-prev" aria-label="" tabindex="0" role="button"></a>',
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        rows: 0,
        fade: true,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    fade: false,
                    adaptiveHeight: true
                }
            }
        ]
    };
    autoSlider($('.programs-container__slider'), programsSliderSettings);

    var teamSliderSettings = {
        autoplay: false,
        speed: 600,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        rows: 0,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    adaptiveHeight: true
                }
            }
        ]
    };
    autoSlider($('.team-container__slider-container'), teamSliderSettings);

    // дополнительные стрелки для слайдеров
    $('.slider-arrows a').on('click', function () {
        var $slider = $('#' + $(this).parent().data('slider'));

        if (!$slider.length) return false;

        if ($(this).is('.slider-arrows__left')) {
            $slider.slick('slickPrev');
        } else if ($(this).is('.slider-arrows__right')) {
            $slider.slick('slickNext');
        }
    });

    var awardsSliderSettings = {
        autoplay: false,
        speed: 600,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        rows: 0,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    adaptiveHeight: true
                }
            }
        ]
    };
    autoSlider($('.awards-slider'), awardsSliderSettings);

    var caresSliderSettings = {
        autoplay: false,
        speed: 600,
        arrows: true,
        nextArrow: '<a data-role="none" class="slick-next" aria-label="" tabindex="0" role="button"></a>',
        prevArrow: '<a data-role="none" class="slick-prev" aria-label="" tabindex="0" role="button"></a>',
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        rows: 0,
        fade: true,
        cssEase: 'linear'
    };
    autoSlider($('.cares-container__slider'), caresSliderSettings);

});

/*статьи скрытие текста*/
$('.details-show').on('click', function () {
    $('.news-text_second').toggle('slow');
    $('.details-show').toggle();
    $('.details-hide').toggle();
    $('.details-show').removeClass('active');
});
$('.details-hide').on('click', function () {
    $('.news-text_second').toggle();
    $('.details-show').toggle();
    $('.details-hide').toggle();
});

//Функция отображения PopUp
function PopUpShow(){
    $("#popup1").css('display','flex');
}
//Функция скрытия PopUp
function PopUpHide(){
    $("#popup1").hide();
}

$(".cmodal" ).click(function() {
    $("#popup1").css('display','flex');
});

/*форма обратной связи*/
$( ".when-time" ).click(function() {
    $( ".when-now > .when-toggle" ).addClass( "toggle-hide" );
    $( ".when-time > .when-toggle" ).removeClass( "toggle-hide" );
    $( ".mf-time" ).show( "slow" );
});
$( ".when-now" ).click(function() {
    $( ".when-now > .when-toggle" ).removeClass( "toggle-hide" );
    $( ".when-time > .when-toggle" ).addClass( "toggle-hide" );
    $( ".mf-time" ).hide( "slow" );
});
$(document).ready(function(){
    $('.phone-input').inputmask("+7(999)999-99-99");  //static mask
    $('.phone-input').inputmask({mask: "+7(999)999-99-99"});  //static mask
});

$( ".search-call" ).click(function() {
    $( ".search-container" ).show( "slow" );
});
$( ".search-remove" ).click(function() {
    $( ".search-container" ).hide( "slow" );
});
/* цены */

function ShowPrice(){
    $(".pr-items__inner").show("slow");
}
function HidePrice(){
    $(this).children('ul').toggleClass('active-pr', 1000);
    $(this).find('h2').toggleClass('active-arr', 1000);
    $(this).find('.pr-slide').toggleClass('active-bg', 1000);

}

/*цены*/
// $('.pr-category').click(function(){
//     $(this).children('ul').toggleClass('active-pr', 1000);
//     $(this).find('i').toggleClass('active-arr', 1000);
// });
$('.pr-category').click(function(){
    $(this).children('ul').toggleClass('active-pr', 1000);
    $(this).find('h2').toggleClass('active-arr', 1000);
    $(this).find('.pr-slide').toggleClass('active-bg', 1000);
});
// $(function(){
//     $('#selecttheme').change(function(){
//         var color = $('#selecttheme :selected').attr("data-sl");
//         var rowSelector = 'pr-category:eq(' + parseInt(this.val()) + ')';
//         console.log(color);
//         $('.pr-category').has(color).toggleClass('active-arr', 1000);
//         $(".pr-category").find(color).toggleClass('active-arr', 1000);
//         $(".pr-list").find($('#selecttheme :selected').attr("data-sl")).addClass('active-arr', 1000);
//         $(`.pr-list li[data-sl = ${color}]`).addClass('active-pr');
//     })
// });
// $("#selecttheme").change(function() {
//     var selected = $('#selecttheme :selected').attr("data-sl");
//     $(".pr-list li:eq(selected)").css("color", "red");
// });

$(function(){
    $('#selecttheme').change(function(){
        var color = $('#selecttheme :selected').attr("data-sl");
        var u0 = $(".pr-list li[data-sl = " + color + "]");
      //  u0.addClass('active-pr');
         $('.pr-items__inner').removeClass('active-pr');
         $('.pr-title').removeClass('active-arr', 1000);
         $('.pr-slide').removeClass('active-bg', 1000);
        // $('.pr-category').hide('slow');
        // u0.show();
        u0.children('ul').toggleClass('active-pr', 1000);
        u0.find('h2').toggleClass('active-arr', 1000);
        u0.find('.pr-slide').toggleClass('active-bg', 1000);
        var target = $(u0).attr('data-sl');
         $('html, body').animate({scrollTop: $(u0).offset().top - 300}, 1000);
        return false;
    })
});

/*отзывы*/

$(".feedback-more").click(function () {
    $(this).prev().fadeOut();
    $(this).next().fadeIn();
    //$(".feedback-item").hide();
    $(this).fadeOut();
    $(this).next().next().fadeIn();
    // $(this).parent(".feedback-item").css("position","absolute");
    // $(this).parent(".feedback-item").css("transition","2s");
    // $(this).parent(".feedback-item").css("z-index","3000");
    // $(this).parent(".feedback-item").css("width","100%");
    // $(this).parent(".feedback-item").css("height","100%");
    $(this).parent(".feedback-item").addClass("feedback-details");
    // $(this).parent(".feedback-item").show("slow");
});
$(".feedback-hide").click(function () {
    $(this).prev().fadeOut();
    $(this).prev().prev().fadeIn();
    $(this).prev().prev().prev().fadeIn();
    //$(".feedback-item").hide();
    $(this).fadeOut();
    // $(this).next().next().fadeIn();
    // $(this).parent(".feedback-item").css("position","absolute");
    // $(this).parent(".feedback-item").css("transition","2s");
    // $(this).parent(".feedback-item").css("z-index","3000");
    // $(this).parent(".feedback-item").css("width","100%");
    // $(this).parent(".feedback-item").css("height","100%");
    $(this).parent(".feedback-item").removeClass("feedback-details");
    // $(this).parent(".feedback-item").show("slow");
})

/*banner price*/

$(".price-show").click(function () {
    $(".inner-item ").css("display", "flex");
    $(this).fadeOut();
    $(this).next().show();
})


$(".price-hide").click(function () {
    $(".inner-item:nth-child(1n+6)").css("display", "none");
    $(this).fadeOut();
    $(this).prev().show();
    $(this).prev().removeClass("active");
    $('html, body').animate({scrollTop: $("#bprice").offset().top - 500}, 1000);
    return false;
})
/*slaider inner service*/

'use strict';
var multiItemSlider = (function () {
    return function (selector, config) {
        var
            _mainElement = document.querySelector(selector), // основный элемент блока
            _sliderWrapper = _mainElement.querySelector('.slider__wrapper'), // обертка для .slider-item
            _sliderItems = _mainElement.querySelectorAll('.slider__item'), // элементы (.slider-item)
            _sliderControls = _mainElement.querySelectorAll('.slider__control'), // элементы управления
            _sliderControlLeft = _mainElement.querySelector('.slider__control_left'), // кнопка "LEFT"
            _sliderControlRight = _mainElement.querySelector('.slider__control_right'), // кнопка "RIGHT"
            _wrapperWidth = parseFloat(getComputedStyle(_sliderWrapper).width), // ширина обёртки
            _itemWidth = parseFloat(getComputedStyle(_sliderItems[0]).width), // ширина одного элемента
            _positionLeftItem = 0, // позиция левого активного элемента
            _transform = 0, // значение транфсофрмации .slider_wrapper
            _step = _itemWidth / _wrapperWidth * 100, // величина шага (для трансформации)
            _items = []; // массив элементов
        // наполнение массива _items
        _sliderItems.forEach(function (item, index) {
            _items.push({ item: item, position: index, transform: 0 });
        });

        var position = {
            getMin: 0,
            getMax: _items.length - 1,
        }

        var _transformItem = function (direction) {
            if (direction === 'right') {
                if ((_positionLeftItem + _wrapperWidth / _itemWidth - 1) >= position.getMax) {
                    return;
                }
                if (!_sliderControlLeft.classList.contains('slider__control_show')) {
                    _sliderControlLeft.classList.add('slider__control_show');
                }
                if (_sliderControlRight.classList.contains('slider__control_show') && (_positionLeftItem + _wrapperWidth / _itemWidth) >= position.getMax) {
                    _sliderControlRight.classList.remove('slider__control_show');
                }
                _positionLeftItem++;
                _transform -= _step;
            }
            if (direction === 'left') {
                if (_positionLeftItem <= position.getMin) {
                    return;
                }
                if (!_sliderControlRight.classList.contains('slider__control_show')) {
                    _sliderControlRight.classList.add('slider__control_show');
                }
                if (_sliderControlLeft.classList.contains('slider__control_show') && _positionLeftItem - 1 <= position.getMin) {
                    _sliderControlLeft.classList.remove('slider__control_show');
                }
                _positionLeftItem--;
                _transform += _step;
            }
            _sliderWrapper.style.transform = 'translateX(' + _transform + '%)';
        }

        // обработчик события click для кнопок "назад" и "вперед"
        var _controlClick = function (e) {
            if (e.target.classList.contains('slider__control')) {
                e.preventDefault();
                var direction = e.target.classList.contains('slider__control_right') ? 'right' : 'left';
                _transformItem(direction);
            }
        };

        var _setUpListeners = function () {
            // добавление к кнопкам "назад" и "вперед" обрботчика _controlClick для событя click
            _sliderControls.forEach(function (item) {
                item.addEventListener('click', _controlClick);
            });
        }

        // инициализация
        _setUpListeners();

        return {
            right: function () { // метод right
                _transformItem('right');
            },
            left: function () { // метод left
                _transformItem('left');
            }
        }

    }
}());

var slider = multiItemSlider('.slider')

// $(".modal-call").click(function () {
//     console.log("test");
// })
// $(".modal-call").click(function () {
//   console.log("test");
// })

